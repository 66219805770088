import React, { Component } from 'react';
import { LoginForm } from '../components/LoginForm';
import { AuthenticationService } from '../services';
import { Redirect } from 'react-router';


export default class LogoutPage extends Component {

    private authenticationService : AuthenticationService;

    constructor(props: Readonly<{}>) {
        super(props);

        this.authenticationService = new AuthenticationService();
    }

    render () {
        console.log('LogoutPage::render');
        
        this.authenticationService.logout();

        return ( 
            <Redirect to="/" />
        );
    }
}