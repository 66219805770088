import React, { useState, useContext, useEffect, ReactElement } from 'react';
import {Button, Layout, Tabs as RcTabs} from 'antd';
import {DoubleRightOutlined, DoubleLeftOutlined} from '@ant-design/icons';
import 'antd/dist/antd.css';

const { Content, Sider } = Layout;

export interface ITabProps{
  tabKey: string;
  title: string;
  icon: JSX.Element;
  visible?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export interface ITabPanelState{
//  collapsed: boolean;
  hidden: boolean;
  activeTab: string;
}

export interface ITabPanelProps{
//  children : React.ReactElement<any>;
  width: string;
  collapsedWidth: string;
  hidden: boolean;

  activeTab: string;
  setActiveTab(value:string): void;
  collapsed: boolean;
  setCollapsed(value: boolean): void;
}

export class Tab extends React.Component<ITabProps,{}>{
  render(){
    const {children} = this.props;
    return (
      {children}
    )
  }
}

export default class TabPanel extends React.Component<ITabPanelProps, ITabPanelState>{

  state = {
    hidden: false
  } as ITabPanelState;

//   defaultProps = {
//     // TODO: default props using state here for other use cases, 
//     // for when active tab or collapsed are not mainteained in the parent component 
//   }

  generateStripMenu = () => {
    const {activeTab, setActiveTab, collapsed, setCollapsed} = this.props;

    return(
      <React.Fragment>
      {
        React.Children.map(this.props.children, (child)=>{

          const tab:Tab = child as Tab;
          if(!tab){
            return undefined;
          }

          const {icon, tabKey, title, visible} = tab.props;

          if(visible == false){
            console.warn("Hiding active tab...");
  
            return undefined;
          }
  
          console.log("Creating button", {"child-props": tab.props});
  
          return(<Button
            icon={icon}
            size="middle"
            title={title}
          onClick={() => { setActiveTab(tabKey); setCollapsed(false)} } />)
        })}
        
      </React.Fragment>
    );
  }

  generateContent = ( args: {collapsed: boolean} ) => {
    if(args.collapsed == true){
      return <this.generateStripMenu />;
    }

    const {activeTab, setActiveTab} = this.props;

    console.log("TabPanel::generateContent",{activeTab:activeTab});

    return (
       <RcTabs animated={false} style={{marginLeft:10, padding: 5}} activeKey={activeTab} 
                        onChange={(activeKey: string)=>{console.log("set active tab: ", activeKey); setActiveTab(activeKey) }}>
        {/*   
          // TODO: CHECK inconsistencies
          // This is the correct way to map child nodes, but has an issue with setting active tab, sets "Info/.0" as Selected tab fro
          // React.Children.map(this.props.children, (child)=>{
          // const tab:Tab = child as Tab;
          //   if(tab == null){
          //     return undefined;
          //   }

          // this is  a little strange, previous, more correct version is causing issues but this works.
        */}
        { (React.Children.toArray(this.props.children) as Tab[]).map( (tab:Tab) => {

            const { title, icon, visible, tabKey, children } = tab.props;

            console.log("TabPanel::generateContent - Creating tab", {"tab-props": tab.props});

            if(visible == false){
              console.log("TabPanel::generateContent  - Tab is not visible", {"title": title});
              return;
            }

            return (
              <RcTabs.TabPane
                  tab={<span>{icon} {title}</span>}
                  key={tabKey}>
                  {children}
              </RcTabs.TabPane>
            )
          })
        }
      </RcTabs>
    );
  }

  render (){
    const {width, collapsedWidth, hidden, activeTab, setActiveTab, collapsed, setCollapsed} = this.props;
    const arrow = collapsed ? <DoubleLeftOutlined /> : <DoubleRightOutlined />;

    return (
      <Sider theme="light" trigger={null} collapsible collapsed={collapsed} hidden={hidden} width={width} collapsedWidth={collapsedWidth}>

        <Button
            icon={arrow}
            size="middle"
            style={{borderColor:"transparent"}}
            onClick={() => setCollapsed(!collapsed)} />

        <this.generateContent collapsed={collapsed}/>

      </Sider>
    );
  }
}