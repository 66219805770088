import React, { Component, useState, useEffect } from 'react';


import { BrowserRouter as Router, Route } from "react-router-dom";

import { Layout } from 'antd';
import AuthenticatedRoute from './components/AuthenticatedRoute';

import AnalyticsPage, { ActiveMenuItem, ChartBy } from './pages/AnalyticsPage';
import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';
import ReportPage from './pages/ReportPage';

import 'antd/dist/antd.css';

// TODO: Bad.. refactor 
import { MapState } from './components/Map';
import { AuthenticationService } from './services';
import { FullColorPalette } from './components/Util';

export interface IInformationEntity{
  name?: String;
  id?: number;
}

export enum ActiveTab {
  Info,
  Tourist,
  Report,
  Debug
}

export type ActiveTabStrings = keyof typeof ActiveTab;

export type Selection = 
{
  activeMenuItem: ActiveMenuItem;
  setActiveMenuItem(value: ActiveMenuItem): void;

  selected: IInformationEntity;
  setSelected(value: IInformationEntity) : void;

  reportData: IReportItem[];
  addToReport(value: IInformationEntity) : void;
  removeFromReport(value: IReportItem) : void;

  activeTab: ActiveTab;
  setActiveTab(value: ActiveTab) : void;

  selectedDateRange: ISelectedDateRange;
  setSelectedDateRange(value: ISelectedDateRange): void;

  mapState: MapState;
  setMapState(value: Partial<MapState>): void;

  spendingLimits: {lower: number, upper: number}; 
  setSpendingLimits(value: {lower: number, upper: number}): void;

  showAllNationalities: boolean;
  setShowAllNationalities(value: boolean): void;

  chartByValue: ChartBy;
  setChartByValue(value: ChartBy): void;

  
  nationalities: string[], 
  nationalityPalette: { [nationality: string] : string; }
}

export const SelectionContext = React.createContext<Partial<Selection>>({
  reportData: []
});

export interface IReportItem {
  key? : {id: number, type: string};
  name?: string;
}

// todo: viewModel
export interface IMerchantStoreDetails {
  id: number;
  merchantID: number;

  storeName: string;
  merchantName: string;
  merchantMarket: string,

  touristCount: number;
  totalPurchases: number;
}


// TODO: Change to a proper date
export interface ISelectedDateRange {
  fromDay: string;
  toDay: string;
}

const useLocalStorage = <T extends {}>(key: string, defaultValue: T) =>  {
  const [value, setValue] = React.useState(() => {
    const stickyValue = window.localStorage.getItem(key);
    return stickyValue !== null
      ? JSON.parse(stickyValue)
      : defaultValue;
  });
  React.useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue];
}

if (process.env.NODE_ENV !== 'development') {
  console.log = () => {}
}

//export default class App extends Component<{}, {}> {
// APP Functional component
export default (() => {

    // TODO: Figure out default / initial values here, this feels like the wrong place to set them...  
    const [selected, setSelected] = useState<IInformationEntity | undefined>(undefined);
    const [reportData, setReportData] = useLocalStorage<IReportItem[]>("ReportItem", []);
    const [activeMenuItem, setActiveMenuItem] = useState<ActiveMenuItem>(ActiveMenuItem.Merchant);
    const [activeTab, setActiveTab] = useState<ActiveTab>(ActiveTab.Report);
    const [selectedDateRange, setSelectedDateRange] = useState<ISelectedDateRange>({fromDay : "2019-11-01", toDay : "2019-11-30"});
    const [spendingLimits, setSpendingLimits] = useState<{lower: number, upper: number}>({lower:-1, upper:-1});
    const [showAllNationalities, setShowAllNationalities] = useState(true);
    const [chartByValue, setChartByValue] = useState(ChartBy.TouristCount);

    // 
    const [nationalities, setNationalities] = useState<string[]>([]);
    const [nationalityPalette, setNationalityPalette] = useState<{ [nationality: string] : string; }>();

    const [mapState, setMapStateS] = useState<MapState>({
      data: undefined,
      store: undefined,
      center: [ -0.10, 51.506922],
      zoom: [12],
      heatmapIntensity: 1.2,
      heatmapNationalities: [],
    });

    
    const createNationalityPallette = (nationalities:string[], setColors:{[nationality: string]: string}) => {
      let availableColors = ([] as string[]).concat(FullColorPalette);
      
      console.log("ReportPage::createNationalityPallette starting", {nationalities: nationalities});

      let mappedColors : { [nationality: string] : string; } = setColors;

      nationalities.forEach( nationality =>{
          // Color is already on map
          if(setColors[nationality]!= undefined){
              return;
          }

          //
//            console.log("ReportPage::createNationalityPallette dataKeys.map", {x:x});
          let nextColor = availableColors.pop();
//            console.log("ReportPage::createNationalityPallette dataKeys.map", {x:x, nextColor:nextColor});
          if(nextColor == undefined){
              availableColors = ([] as string[]).concat(FullColorPalette);
              nextColor = availableColors.pop();
          }

          mappedColors[nationality] = nextColor!;
      });

      console.log("ReportPage::createNationalityPallette", {mappedColors:mappedColors});

      return mappedColors;
  }

    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // TODO: TODO: FIX AUTH    
    // TODO: figure this out...  :/
    const authenticationService = new AuthenticationService();

    useEffect(()=>{
        // !!!! MOVED HERE before release to get auth working with the nationality check, Refactor!!!
        // TODO: Refactor request / fetch 
        const currentUser = authenticationService.currentUserValue;

        if(currentUser){
          const requestOptions = {
              headers: { 
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${currentUser.authentication.token}` 
              }
          };
          
          // TODO: remove hardcoded strings
          fetch('api/MarketStatistics/Nationalities?fromDay=2019-11-01&toDay=2019-12-31', requestOptions)
          .then(res => res.json())
          .then((data) => {
              console.log("TouristDetailPanel::useEffect Load Nationalities for full dataset ",{'data': data});
              setNationalities(data);

              // use nationalities in the pie data..
              setNationalityPalette(createNationalityPallette(data, 
                {
                  "AMERICAN" : "#178fe5",
                  "CHINESE": "#3eb55f",
                  "Other": "#ffb932",

  /*
                  other colours on same saturation and lightness

                  "#41c3c8",
                  "#b53e94",
                  "#e56d17",
                  "#C84641",
                  "#3278ff",
  */
                }
              ));
    
          })
          .catch(console.log);    
        }
      },
      [selectedDateRange, authenticationService.currentUserValue]
    );
    // /!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

    const addToReport = (value: IInformationEntity) => {    
      let key = {id: value.id!, type:"MerchantStore"};
      
      console.log("addToReport", key);
      //TODO: ehhh sort types here...
      if(indexOfReportItemKey(reportData, key) == -1)
      {
        setReportData([...reportData, {name: value.name!, key: key} as IReportItem ]);
      }
    }

    const removeFromReport = (value: IReportItem) => {
      console.log("removeFromReport: initial reportData", reportData);

      console.log("item to remove: ", value);
      //TODO: better way to deal with searching here, extend to additional types(region, market)...
      let ind = indexOfReportItemKey(reportData, value.key!);

      reportData.splice(ind, 1);
      console.log("reportData.splice: ", reportData);
      setReportData([...reportData]);
    }
    
    // emulate setState behaviour
    const setMapState = (value: Partial<MapState>) => {
      setMapStateS({...mapState, ...value});
    }

    const store = {
      selected, setSelected,
      reportData, addToReport, removeFromReport,
      activeTab, setActiveTab,
      selectedDateRange, setSelectedDateRange,
      mapState, setMapState,
      activeMenuItem, setActiveMenuItem,
      spendingLimits, setSpendingLimits,
      showAllNationalities, setShowAllNationalities,
      chartByValue, setChartByValue,

      nationalities, nationalityPalette
    }

    return (
      <SelectionContext.Provider value={store}>
        <Router>
          <Layout style={{height: "100vh"}}>
            <AuthenticatedRoute exact={true} path="/" component={AnalyticsPage} />
            <AuthenticatedRoute exact={true} path="/report" component={ReportPage} />
            <Route path="/login" component={LoginPage} />
            <Route path="/logout" component={LogoutPage} />
          </Layout>
        </Router>
      </SelectionContext.Provider>
    );
}) as React.FunctionComponent;


//
function indexOfReportItemKey(reportData: IReportItem[], key: {id: number, type: string} | undefined) {
  let ind = -1;
  reportData.forEach((item, index) => {
    console.log("comparing",item.key, key);
    if (item.key?.id == key?.id &&  item.key?.type == key?.type) {
      ind = index;
      console.log("matching key at index", index);
    }
  });
  return ind;
}

