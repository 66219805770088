import React, { Component } from 'react';
import { BehaviorSubject, Observable } from 'rxjs';

import config from '../config';

//TODO:
interface Response { 
    text: () => Promise<string>; ok: any; status: number; statusText: any; 
}

export interface IUser{
    id: string;

}

let storageUser =  localStorage.getItem('currentUser') != null ? JSON.parse(localStorage.getItem('currentUser')!) : null;
const currentUserSubject = new BehaviorSubject( storageUser );

const ping = (event: string) => {
    // log application event...
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': currentUserSubject.value ? `Bearer ${currentUserSubject.value.authentication.token}` : ''
        },
        body: JSON.stringify({ "EventType": event  })
    };

    fetch(`${config.apiUrl}/api/authentication/ping`, requestOptions)
    .then((response: { text: () => Promise<string>; ok: any; status: number; statusText: any; }) => {
        //TODO: remove this
        console.log("ping response", {requestOptions: requestOptions, response: response} );
    });
}

ping("ApplicationLoad");

export class AuthenticationService {

    currentUser: Observable<string | null> = currentUserSubject.asObservable();

    get currentUserValue () { 
        return currentUserSubject.value 
    }

    login(email: string, password: string) {
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ "Email": email, "Password": password })
        };

        return fetch(`${config.apiUrl}/api/authentication/login`, requestOptions)
            .then((response: { text: () => Promise<string>; ok: any; status: number; statusText: any; }) => {
                console.log("AuthenticationService::login fetch", {requestOptions: requestOptions, response: response} );
                
                return response.text().then((text: string) => {
                    const data = text && JSON.parse(text);

                    console.log("AuthenticationService::login data", data);

                    if (!response.ok) {

                        if ([401, 403].indexOf(response.status) !== -1) {
                            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                            this.logout();
                            window.location.reload(true);
                        }
                        ping("LoginFailure");
                        const error = (data && data.message) || response.statusText;
                        return Promise.reject(error);
                    }
                    return data;
                })
            })
            .then(authenticatedUser  => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(authenticatedUser));
                currentUserSubject.next(authenticatedUser);

                console.log("AuthenticationService::login response from server", {"authenticatedUser": authenticatedUser});
                ping("Login");
                return authenticatedUser;
            });
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');

        ping("Logout");
        currentUserSubject.next(null);
    }
}