import React, { Component, useState, useContext, useEffect } from 'react';
// @ts-ignore
import Please from "pleasejs";
import {history} from '../components/History';

import { Layout, Table, Row, Col, Radio, Button } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';

import { ResponsiveContainer, LineChart, Line, Tooltip, LineProps, XAxis, YAxis, CartesianGrid, PieChart, Pie, Cell, TooltipProps } from 'recharts';
import { SelectionContext } from '../App';
import { formatDate, PalleteColor, FullColorPalette, formatCurrency } from '../components/Util';
import { random } from 'lodash';

import { AuthenticationService } from '../services';

import ReportPageMerchantDetailsPanel from '../components/ReportPageMerchantDetailPanel';
import { TableRowSelection, Key } from 'antd/lib/table/interface';
import { ChartBy } from './AnalyticsPage';
import { formatCountdown } from 'antd/lib/statistic/utils';

const { Content, Sider } = Layout;



// TODO: remove
const data = [
    {"day":"2019-11-01T00:00:00","dailyTotal":266914.26},{"day":"2019-11-02T00:00:00","dailyTotal":173126.30000000013},{"day":"2019-11-03T00:00:00","dailyTotal":127446.62999999998},{"day":"2019-11-04T00:00:00","dailyTotal":286480.9},{"day":"2019-11-05T00:00:00","dailyTotal":241935.50000000023},{"day":"2019-11-06T00:00:00","dailyTotal":241403.92000000016},{"day":"2019-11-07T00:00:00","dailyTotal":301073.94000000006},{"day":"2019-11-08T00:00:00","dailyTotal":285950.44999999966},{"day":"2019-11-09T00:00:00","dailyTotal":338371.73},{"day":"2019-11-10T00:00:00","dailyTotal":159839.58999999994},{"day":"2019-11-11T00:00:00","dailyTotal":329633.01999999967},{"day":"2019-11-12T00:00:00","dailyTotal":349111.06},{"day":"2019-11-13T00:00:00","dailyTotal":489637.24999999977},{"day":"2019-11-14T00:00:00","dailyTotal":246898.64000000013},{"day":"2019-11-15T00:00:00","dailyTotal":260716.83000000028},{"day":"2019-11-16T00:00:00","dailyTotal":507261.08999999997},{"day":"2019-11-17T00:00:00","dailyTotal":156342.03999999995},{"day":"2019-11-18T00:00:00","dailyTotal":196718.90000000005},{"day":"2019-11-19T00:00:00","dailyTotal":443728.1099999999},{"day":"2019-11-20T00:00:00","dailyTotal":242773.3200000001},{"day":"2019-11-21T00:00:00","dailyTotal":231379.75000000026},{"day":"2019-11-22T00:00:00","dailyTotal":263223.3900000003},{"day":"2019-11-23T00:00:00","dailyTotal":221400.69},{"day":"2019-11-24T00:00:00","dailyTotal":126301.10999999999},{"day":"2019-11-25T00:00:00","dailyTotal":347213.68},{"day":"2019-11-26T00:00:00","dailyTotal":252267.56000000023},{"day":"2019-11-27T00:00:00","dailyTotal":262868.0500000003},{"day":"2019-11-28T00:00:00","dailyTotal":310946.0299999998},{"day":"2019-11-29T00:00:00","dailyTotal":387347.1800000001}
];

//TODO: check, not sure this is correct here..
export default React.memo( (()=>{

    const createPallette = (size: number) => {
        
        let x = Please.make_color({
//            base_color: PalleteColor[0],
            colors_returned: size
        }) as any;

        console.log("color pallette: ", {pallette: x});

        return x;
    }

    // context
    const {reportData, selectedDateRange, chartByValue, setChartByValue, nationalityPalette, nationalities} = useContext(SelectionContext);

    // state
    const [graphData, setGraphData] = useState<{dataKeys: {name: string, key: {id: number, type: string}}[], chartData: any[], pieData: any[] } | undefined>(undefined);
    const [pallette, setPallette] = useState<string[]>([]);

    const [merchantNameLookup, setMerchantNameLookup] = useState<{ [keyString: string]: string}>({});
    
    const [activeRowKeys, setActiveRowKeys] = useState<Key[]>([]);

    const [selectedRowKey, setSelectedRowKey] = useState<Key | undefined>(undefined);
    const [hoverSelectedRowKey, setHoverSelectedRowKey] = useState<Key | undefined>(undefined);
    const [hoverNationality, setHoverNationality] = useState<string>();

    const ReportPageChartTooltip = ( arg: any ) => {

        let tArg = arg as  {payload: any[], active: boolean}
            console.log("::ReportPageChartTooltip", {arg: arg});
      
        if (arg.active) { // Is this correct now
          return (
            <div className="custom-tooltip">
              <p className="label">{formatDate(new Date(tArg.payload[0].payload.day))}</p>
              {
                  //...
                  tArg.payload.sort((a, b)=>(b.value > a.value) ? 1 : -1).map( x => {
                      if(x.value == 0)
                        return undefined;
    
                      let keyString = x.name.replace("data.", "");
                      return (
                        <p key={x.name} className="desc">{merchantNameLookup[keyString]}: {formatCurrency(x.value)}</p>
                      )
                  }) 
              }
    
            </div>
          );
        }
        
        return <div />;
      }

    const getActiveRow = () => {
        if(hoverSelectedRowKey != undefined) 
            return hoverSelectedRowKey as Key;
            
        return selectedRowKey as Key;
    };

    // TODO: fix
    const authenticationService = new AuthenticationService();

    // fetch graph data from server based on the data stored in report data 
    useEffect(()=>{
        console.log("render::useEffect - page");
        // fetch from server
        if(reportData){
            const currentUser = authenticationService.currentUserValue;

            console.log("Packaging report Data for body on api call: ", reportData)

            const requestOptions = {
                headers: { 
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${currentUser.authentication.token}` 
                },
                method: "POST",
                body:JSON.stringify(reportData)
            };

            fetch('api/MarketStatistics/ReportData?fromDay='+selectedDateRange!.fromDay+'&toDay='+selectedDateRange!.toDay, requestOptions)
            .then(res => res.json())
            .then((data) => {
                console.log("ReportGraphData::", {'data': data});
                setGraphData(data);

                // get actual keys off dataKeys list
                let keys:Key[] = [];
                let merchantName: {[keyString: string]: string} = {};
                (data.dataKeys as {name: string, key: {id: number, type: string}}[]).forEach((x)=>{
                    keys.push(x.key.type + "_" + x.key.id);
                    merchantName[x.key.type + "_" + x.key.id] = x.name;
                })

                setActiveRowKeys(keys);
                setMerchantNameLookup(merchantName);

                setPallette(createPallette(keys.length));
            })
            .catch(console.log)
        }
        else{
            setGraphData(undefined);
        }
    }, [reportData]);

    const selection =  {
        selectedRowKeys: activeRowKeys,
        onChange: (selectedRowKeys: Key[], selectedRows: any) => {
            setActiveRowKeys(selectedRowKeys);

            console.log({"selectedRowKeys": selectedRowKeys,'selectedRows': selectedRows});
        },
        onSelectAll: (selected: boolean, selectedRows: any[], changeRows: any[]) => {
            console.log(selected, selectedRows, changeRows);
        }
    } as TableRowSelection<any>

    return (
        <>
            <Layout style={{height: "30vh", backgroundColor:"#F0F2F5"}}>
                <Content style={{overflow:"hidden", backgroundColor:"#414141"}}>

                {graphData &&

                    <ResponsiveContainer>
                        <LineChart margin={{ top: 20, right: 30, left: 10, bottom: 20 }} data={graphData.chartData}>
                            <YAxis dx={-10} axisLine={false}/>
                            <XAxis dy={10}  axisLine={false} dataKey="day" tickFormatter={(label) => new Date(label).getDate() } />
                        {
                            graphData.dataKeys.map((key, index)=>
                            {
                                // dont show if not on the selection list
                                if(activeRowKeys == undefined ||  !activeRowKeys.find((x)=>{
                                    return x == key.key.type + "_" + key.key.id;
                                })){
                                    return undefined;
                                }

                                let activeRow = getActiveRow();
                                let rowKey =  key.key.type + '_' + key.key.id;
                                let isActive = activeRow != undefined && (activeRow == key.key.type + "_" + key.key.id );

                                return (                                
                                    <Line isAnimationActive={false} cursor="false" type='monotone' stroke={pallette![index]} strokeWidth={isActive ? 2 : 1} 
                                        // onMouseMove={ (line: LineProps) => {
                                        //     setHoverSelectedRowKey(rowKey);
                                        // }}
                                        // onMouseOver={ (line: LineProps) => {
                                        //     setHoverSelectedRowKey(rowKey);
                                        // }}
                                        // onMouseOut={ (line: LineProps) => {
                                        //     setHoverSelectedRowKey(undefined);
                                        // }}
                                        
                                        key={rowKey}
                                        dataKey={"data." + rowKey}
                                        opacity={isActive ? 1 : 0.5}
                                    />
                                )
                            })
                        }
                            
                        <Tooltip content={<ReportPageChartTooltip />} />
                        {/* <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(parseFloat(value.toString()))}/> */}

                        </LineChart>
                    </ResponsiveContainer>
                }
                </Content>

                <Sider width={"20vw"} style={{backgroundColor:"#414141"}}>
                { graphData && 
                    <ResponsiveContainer>
                        <PieChart margin={{ top: 10, bottom: 10 }}>
                            <Pie label={(x)=>{return x.name}} nameKey="name" dataKey={chartByValue == ChartBy.TotalSpend ? "total" : "touristCount"} data={graphData.pieData} paddingAngle={0.01}>
                            {   nationalityPalette && 
                                graphData.pieData.map((entry, index) => <Cell key={entry.name} className="headerNationalityHover" fill={nationalityPalette![entry.name]}
                                // onMouseEnter={(e)=>{setHoverNationality(entry.name)}} 
                                // onMouseLeave={(e)=>{setHoverNationality(undefined)}}
                                
                                />)
                            }
                            </Pie>
                            <Tooltip formatter={(value) =>  chartByValue == ChartBy.TotalSpend ? formatCurrency(value as number) : value}/>
                        </PieChart>
                    </ResponsiveContainer>
                }
                </Sider>

            </Layout>
            <Layout style={{height: "10vh"}}>
                <Content style={{marginTop:"15px"}}>
                <Row >
                        <Col span={16}><Button type="link" onClick={()=>{history.go(-1)}}>&lt;&lt; Back</Button></Col>
                        <Col span={2}></Col>
                        <Col span={2}><b>Chart by</b></Col>
                        <Col span={4}>
                            <Radio.Group onChange={(e:RadioChangeEvent)=>{setChartByValue!(e.target.value as ChartBy)}} value={chartByValue}>
                                <Radio value={ChartBy.TotalSpend}>
                                Total Spend 
                                </Radio>
                                <Radio value={ChartBy.TouristCount}>
                                Tourists
                                </Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                </Content>
            </Layout>

            <Layout style={{height: "60vh"}}>
                <Content>
                    <Table
                        pagination={false}
                        columns={[
                            {
                                title: 'Name',
                                dataIndex: 'name',
                                key: 'key'
                            }
                        ]}
                        rowKey={e=>{return e.key.type+"_"+e.key.id}}
                        rowSelection={selection}
                        dataSource={graphData?.dataKeys}
                        scroll={{y:"calc( 60vh - 4em )"}}
                        onRow={(record, rowIndex) => {

                            let key = record.key.type + "_" + record.key.id;

                            return {
                              onClick: event => {
                                console.log("Click", {rowIndex: rowIndex, record: record, event: event});
                                
                                // select record.key
                                setSelectedRowKey(key);
                              },
                              onMouseEnter: event => {
                                  console.log("MouseEnter", {rowIndex: rowIndex, record: record, event: event});

                                  // hoverselect record.key
                                  if(activeRowKeys.indexOf(key)!=-1 ){
                                    setHoverSelectedRowKey(key);
                                  }
                              },
                              onMouseLeave: event => {
                                  setHoverSelectedRowKey(undefined);
                              }
                            }
                        }}/>
                </Content>
                <Sider width={"80vw"} style={{overflowX: "hidden", overflowY: "auto", backgroundColor:"#F0F2F5"}} >

                    {
                        activeRowKeys && activeRowKeys.map(
                            (rowKey) =>
                            {
                                if(rowKey == undefined || rowKey == null)
                                    return undefined;

                                let id = parseInt(rowKey.toString().split("_")[1]);
                                let type = rowKey.toString().split("_")[0];

                                // TODO: Move this lookup to a dictionary / Hashtable 
                                let merchantName = merchantNameLookup[rowKey];
                                console.log("activeRowKeys.map::", {x:rowKey});

                                return (
                                    <Row  key={rowKey} style={{display: "flex", flexDirection:"column", flexWrap:"nowrap"}}>
                                        <Col flex={1} onMouseEnter={()=>{console.log("enter " + merchantName); setHoverSelectedRowKey(rowKey);}} 
                                        onMouseLeave={()=>{console.log("leave " + merchantName); setHoverSelectedRowKey(undefined);}}>
                                            {/* TODO: fix this... effect of changing key to react key */}
                                            <ReportPageMerchantDetailsPanel key={rowKey} type={type} id={id} {...{hoverNationality, setHoverNationality}}/>
                                        </Col>
                                    </Row>
                                )
                            }

                        )
                    }
                </Sider>
            </Layout>
        </>
    )
}) as React.FunctionComponent);