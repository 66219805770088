import React, { Component, CSSProperties, useContext } from 'react';
import * as d3 from 'd3';
import { ExtendedFeature, ExtendedFeatureCollection } from 'd3';
import ReactMapboxGL, { MapContext, Feature, Layer, Source, GeoJSONLayer, ScaleControl, ZoomControl, Popup } from 'react-mapbox-gl';

import mapboxgl, { LngLat } from "mapbox-gl";

import {CloseCircleOutlined, CloseCircleTwoTone, PlusSquareFilled, InfoCircleOutlined, InfoCircleFilled, PlusCircleOutlined, FileAddOutlined, ShopOutlined, ShoppingOutlined, GlobalOutlined} from '@ant-design/icons';

import { svg } from './PlanetLogo';
import { LineChart, Tooltip, Line, ResponsiveContainer, BarChart, Bar } from 'recharts';
import { Button } from 'antd';
import { AuthenticationService } from '../services';
import { SelectionContext, Selection, IInformationEntity, ActiveTab, ISelectedDateRange } from '../App';
import { ActiveMenuItem } from '../pages/AnalyticsPage';

const layoutLayer = { 'icon-image': 'planetIcon' };
const image = new Image();
image.src = 'data:image/svg+xml;charset=utf-8;base64,' + btoa(svg);
const images: any = ['planetIcon', image];

// TODO: move setup into config file
const Mapbox = ReactMapboxGL({ accessToken: 'pk.eyJ1IjoiYWxhbi1mbGFoZXJ0eSIsImEiOiJjazd0cXJzaHYwdzJ4M21xcjY4NHRvNnUyIn0.d7VSVjak-omURF7oVGAnew'    });

const CustomTooltip = ( arg: any ) => {

  if (arg.active) { // Is this correct now
    return (
      <div className="custom-tooltip" style={{fontSize: 'x-small', padding: 0, margin: 0, width: 50, overflowX: 'visible'}}>
        <span className="label" style={{padding: 0, margin: 0}}>{formatDate(new Date(arg.payload[0].payload.day))} </span>
        <span className="desc"  style={{padding: 0, margin: 0}}>{new Intl.NumberFormat('en-IE', { style: 'currency', currency: 'EUR' }).format(arg.payload[0].value)}</span>
      </div>
    );
  }
  
  return <div />;
}

const formatDate = (x:Date): string => {
  return x.getDate().toString().padStart(2, "0") + "-" + (x.getMonth()+1).toString().padStart(2, "0");
}

export interface MapProps{
    style?: CSSProperties;

    /// wrong... wrong.... but...
    selectedDateRange?: ISelectedDateRange;

    mapState?: MapState;
    setMapState?(value: Partial<MapState>): void;

    setActiveTab?(value: ActiveTab) : void;

    addToReport?(value: IInformationEntity) : void;

    selected?: IInformationEntity;
    setSelected?(value: IInformationEntity) : void;

    activeMenuItem?: ActiveMenuItem;
}

// TODO: generate types from c# code
export interface MerchantStoreInfo extends IInformationEntity{
  id: number;
  merchantID?: number;
  name: string;
  storeLatLong: LngLat;
  dayTotals: any
}

const getLayerPaint = (intensity: number) =>  {
  return {
    'heatmap-weight': {
      property: 'value',
      type: 'exponential',
      stops: [[0, 0], [5, 2]]
    },

    // Increase the heatmap color weight weight by zoom level
    // heatmap-ntensity is a multiplier on top of heatmap-weight
    'heatmap-intensity': {
      stops: [[0, 0], [5, intensity]]
    },

    // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
    // Begin color ramp at 0-stop with a 0-transparancy color
    // to create a blur-like effect.
    'heatmap-color': [
      'interpolate',
      ['linear'],
      ['heatmap-density'],
  /*
      0,
      'rgba(33,102,172,0)',
      0.25,
      'rgb(103,169,207)',
      0.5,
      'rgb(209,229,240)',
      0.8,
      'rgb(253,219,199)',
      1,
      'rgb(239,138,98)',
      2,
      'rgb(178,24,43)'
  */
      0,
      'rgba(33,102,172,0)',
      0.25,
      '#41c3c8',
      0.5,
      '#41c3c8',
      0.8,
      '#41c3c8',
      1,
      '#3eb55f',
      2,
      '#3eb55f'
    ],

    // Adjust the heatmap radius by zoom level
    'heatmap-radius': {
      stops: [[0, 1], [5, 50]]
    }
  }
};


export interface MapState{
  data?: ExtendedFeatureCollection<ExtendedFeature<GeoJSON.Point>>;
  heatmapData? : any; // TODO:
  heatmapIntensity? : number;
  heatmapNationalities?: string[];

  // // this is averaged on the serverside
  // heatmapSpendingLimits?: {  lowerLimit: number, upperLimit: number; }

  store?: MerchantStoreInfo;
  center: [number, number];
  zoom: [number];
}

export default class Map extends Component<MapProps, MapState> {
    ref: SVGSVGElement;
    portalRef: HTMLElement;
    map: mapboxgl.Map;

    authenticationService: AuthenticationService;

    constructor( props: Readonly<MapProps>){
      super(props);

      this.authenticationService = new AuthenticationService();
    }

    componentDidMount() {
      console.log("::componentDidMount: Map loaded: " + (this.map != null ? 'true' : 'false'));

      // TODO: !!!!!!integrate into import process and load from webservice
      d3.json("data/merchants.json")
        .then ( (d: any ) => { this.props.setMapState!({data:d})});
    }

    // TODO: fix this any issue if possible, actual issue seems to ge the way layer is exported from the Mapbox
    private onToggleHover( cursor: string, { ...args } ) {
      (args['map'] as any).getCanvas().style.cursor = cursor;
    }

    private markerClick = (id: number, name: string, { ...args } ) => {
      console.log("::markerClick", {'id': id,  'name': name, 'args': args})
      console.log("calling api");

      // TODO: Add date range selection
      const currentUser = this.authenticationService.currentUserValue;
      
      const requestOptions = {
        headers: { 
            'Content-Type': 'application/json',
            Authorization: `Bearer ${currentUser.authentication.token}` 
        }
      };

      fetch('api/MarketStatistics/MerchantStoreDailyTotals?merchantStoreID='+id+'&fromDay='+this.props.selectedDateRange!.fromDay+'&toDay='+this.props.selectedDateRange!.toDay, requestOptions)
      .then(res => res.json())
      .then((data) => {
        console.log("::MerchantDailyTotals", {'id': id, 'name': name, 'data': data})
        this.props.setMapState!({ store: {
          id: id,
          name: name,
          storeLatLong: args.lngLat,
          dayTotals: data
        }//,
//        center: args.lngLat
//        zoom: [14],      
      })})
      .catch(console.log)
    };

    render() {
      if(!this.props.mapState){
        console.log("Map::render", {"this.props": this.props} );
        return("");
      }

      const { data, heatmapData, heatmapIntensity, store,  center, zoom } = this.props.mapState!;
      const { setMapState, setActiveTab, addToReport, selected, setSelected } = this.props;

      var features : ExtendedFeature<GeoJSON.Point>[] = data != null ? data!.features : [];
      console.log(features);

      const layerPaint = getLayerPaint(heatmapIntensity!);

      return (
        <Mapbox
          zoom={zoom}
          center={center}
          style="mapbox://styles/mapbox/light-v10"
          containerStyle={this.props.style}>

          <ZoomControl />
          <ScaleControl />

          {/* Merchant */}
          <Layer type="symbol" id="marker" layout={layoutLayer} images={images}>
          {
            this.props.activeMenuItem == ActiveMenuItem.Merchant && features.map( (feature:any ) => (
                <Feature key={feature.id}
                  coordinates={feature.geometry.coordinates}
                  onMouseEnter={this.onToggleHover.bind(this, 'pointer')}
                  onMouseLeave={this.onToggleHover.bind(this, '')}
                  onClick={this.markerClick.bind(this, feature.id, feature.properties.name)} />
              )
            )
          }
          </Layer>

          { store &&  
            (
              <Popup key={store.id} coordinates={store.storeLatLong.toArray()}>
                <div style={{background: "white", color: "#3f618c", fontWeight: 400, padding: "5px", borderRadius: "5px"}}>
                  <div>{store.name} 
                    <Button style={{borderColor:"transparent"}} icon={<InfoCircleOutlined />} title="Show merchant details" onClick={(e)=>{setSelected!(store); setActiveTab!(ActiveTab.Info); setMapState!({ store: undefined});}}></Button>
                    <Button style={{borderColor:"transparent"}} icon={<ShopOutlined />} title="Add Store to report"  onClick={(e)=>{addToReport!(store); setActiveTab!(ActiveTab.Report); setMapState!({ store: undefined});}}></Button>

                    <Button style={{borderColor:"transparent", float:"right"}} icon={<CloseCircleTwoTone twoToneColor="#3eb55f" />} onClick={(e)=>{setMapState!({ store: undefined})} }></Button> 
                  </div>
                  <BarChart data={store.dayTotals} width={200} height={50}  margin={{ top: 2, right: 10, left: 10, bottom: 2 }}>
                    <Tooltip content={<CustomTooltip />} />
                    <Bar dataKey='dailyTotal' stroke='#3eb55f' fill='#3eb55f' strokeWidth={2} />
                  </BarChart>
                </div>
              </Popup>
            )
          }

          {/* Tourist */}
          <Layer type="heatmap" paint={layerPaint}>
          { this.props.activeMenuItem == ActiveMenuItem.Tourist && heatmapData && heatmapData.map((el: any, index: number) => (
            <Feature key={index} coordinates={el.latlng} properties={el} />
          ))}
        </Layer>

        </Mapbox>
        
      )
    }
  }