import React, { Component, PureComponent } from 'react';
import { identity } from 'lodash';

const monthString = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
const daySuffix = ["th", "st", "nd", "rd"];

const getSuffix = (day: number)  => {
    if(day % 10 > 3)
        return <sup>th</sup>;

    return  <sup>{daySuffix[day % 10]}</sup>;
}

export const formatDate = (x:Date) => {
    return <>{x.getDate()}{getSuffix(x.getDate())} {monthString[x.getMonth()]} {x.getFullYear()}</>
}

const euroFormatter = new Intl.NumberFormat('en-IE', { style: 'currency', currency: 'EUR' });
export const formatCurrency = (value: number): string => {
    return euroFormatter.format(value);
}

export const PalleteColor = [
//        "#f6f6f6",
"#3eb55f",
"#414141",
//    "#f5f5f5",
//    "#178fe5",
"#e8edf0",
"#41c3c8",
//    "#eaeaea",
"#ffb932"]

// TODO: remove
export const OldFullColorPalette = [
    "#33944e",
    "#ffb932",

    "#178fe5",
    "#41c3c8",

    "#e56d17",
    "#C84641",
    "#3278ff",
  
    "#1c522b",
    "#0f5b92",
    "#267f83",
    "#c28000",
    "#832926",
    "#5791ff"
]

export const FullColorPalette = [
    "#5cc77a",
    "#5791ff",
    "#64ced2",
    "#ffc657",
    "#c75caa",
    "#ec873f",
    "#d26864",
    "#5791ff",
  
    "#27733c",
    "#0f5b92",
    "#267f83",
    "#c28000",
    "#73275e",
    "#92450f",
    "#832926",
    "#0042c2",
  
    "#80d498",
    "#7da9ff",
    "#86d9dc",
    "#ffd27d",
    "#d480bd",
    "#f0a26a",
    "#dc8986",
    "#7da9ff",
  
    "#33944e",
    "#1375bb",
    "#31a4a8",
    "#faa400",
    "#943379",
    "#bb5913",
    "#a83531",
    "#0055fa",
  
    "#1c522b",
    "#0a4168",
    "#1b5b5d",
    "#8b5b00",
    "#521c43",
    "#68320a",
    "#5d1d1b",
    "#002f8b"
]
