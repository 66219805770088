export const svg = `<?xml version="1.0" encoding="UTF-8"?>
<svg
   xmlns="http://www.w3.org/2000/svg"
   version="1.0"
   width="20px"
   height="20px"
   viewBox="0 0 20 20">
  <g
     style="fill:#3eb55f;stroke:none"
     transform="matrix(0.00270309,0,0,-0.00270309,-0.01109008,14.850776)">
    <path
       d="m 727,5494 c -1,-1 -20,-4 -42,-8 C 554,5467 467,5435 359,5365 279,5313 187,5223 136,5146 100,5092 26,4940 35,4940 c 2,0 0,-11 -5,-25 -18,-46 -22,-79 -25,-195 -3,-94 1,-131 20,-206 106,-417 510,-669 930,-581 294,61 523,283 600,579 20,78 24,112 21,213 -5,176 -51,316 -149,445 -159,211 -368,317 -640,324 -32,0 -59,0 -60,0 z"
       id="path4"/>
    <path
       d="m 3508,4745 c -2,-2 -50,-6 -108,-9 -58,-4 -116,-9 -130,-11 -14,-2 -43,-6 -65,-10 -168,-26 -391,-98 -563,-182 -92,-46 -323,-188 -342,-212 -3,-3 -32,-28 -65,-56 -283,-236 -500,-559 -619,-921 -15,-44 -28,-89 -30,-100 -3,-10 -11,-48 -20,-84 -22,-94 -34,-181 -42,-295 -5,-75 -10,-2694 -5,-2786 1,-15 59,-16 624,-15 l 622,1 2,388 c 0,214 3,391 6,393 3,3 44,-8 92,-25 351,-124 735,-149 1100,-70 732,157 1325,720 1519,1441 14,51 28,109 31,128 4,19 11,60 16,90 28,161 30,460 4,605 -3,17 -8,46 -11,65 -12,73 -15,86 -30,144 -57,221 -160,449 -285,631 -208,302 -485,540 -804,691 -139,66 -328,130 -434,149 -19,3 -37,7 -40,9 -3,2 -31,7 -61,11 -30,4 -58,9 -61,11 -4,2 -60,7 -125,10 -66,3 -131,7 -146,9 -15,1 -29,1 -30,0 z m 212,-1125 c 70,-15 168,-48 197,-66 12,-8 27,-14 33,-14 24,0 169,-108 235,-174 69,-70 144,-173 179,-249 26,-57 64,-173 71,-215 3,-21 8,-49 11,-62 5,-28 6,-194 0,-225 -2,-11 -7,-40 -11,-65 -4,-25 -18,-78 -32,-118 -139,-420 -577,-684 -1010,-611 -441,74 -764,446 -767,884 -1,167 20,271 82,404 125,269 412,485 686,517 28,4 53,8 55,10 8,8 219,-5 271,-16 z"
       id="path6"/>
  </g>
</svg>`;