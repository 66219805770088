import React, { Component, PureComponent, useState, useEffect, useContext } from 'react';
import { Layout, Empty, List, Row, Col, Button, Typography, DatePicker } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons'

import {LineChart, Line, XAxis, Tooltip, ResponsiveContainer} from 'recharts';

import Modal  from 'react-modal';

import Logo from './planet-tax-free.png';
import { SettingOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { formatDate, formatCurrency } from './Util';
import { AuthenticationService } from '../services';
import { SelectionContext } from '../App';

import moment from 'moment';

const { RangePicker } = DatePicker;

const { Paragraph } = Typography;
const { Header, Footer, Sider, Content } = Layout;

const CustomTooltip = ( arg: any ) => {
  if (arg.active && arg.payload ) { // Is this correct now
    return (
      <div className="custom-tooltip">
        <p className="label">{formatDate(new Date(arg.payload[0].payload.day))}</p>
        <p className="desc">{formatCurrency(arg.payload[0].value)}</p>
      </div>
    );
  }
  
  return null;
}

interface CustomizedAxisTickProps{
  x?: number;
  y?: number;
  stroke?: any;
  payload?: any;
}

class CustomizedAxisTick extends PureComponent<CustomizedAxisTickProps> {
  
  private formatDate(x:Date): string {
    // console.log(x)
    return x.getDay().toString().padStart(2, "0") + "-" + (x.getMonth()+1).toString().padStart(2, "0");
  }

  render() {
    const {
      x, y, stroke, payload
    } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="center" fill="#666">{this.formatDate(new Date(payload.value))}</text>
      </g>
    );
  }
}

export default ( ()=>{
  // TODO: figure this out...  :/
  const authenticationService = new AuthenticationService();

  // global selection context..
  const {selectedDateRange, setSelectedDateRange} = useContext(SelectionContext);

  // state
  const [showSettings, setShowSettings] = useState(false);
  const [chartData, setChartData] = useState<{"day":string,"dailyTotal":number}[]>();

  const [showDateSelectionModal, setShowDateSelectionModal] = useState(false);

  const [modalDateRange, setModalDateRange] = useState({fromDay:  selectedDateRange!.fromDay, toDay: selectedDateRange!.toDay});

  // 
  const settingsClasses = showSettings ? 'hover-settings' : 'hover-settings hide';
  const currentUser = authenticationService.currentUserValue;
  
  const updateDateRange = () => {
    setSelectedDateRange!( {fromDay: modalDateRange.fromDay!, toDay: modalDateRange.toDay!} );
  }

  const resetDateRange = () => {
    setModalDateRange({fromDay:  selectedDateRange!.fromDay, toDay: selectedDateRange!.fromDay});
  }
  
  const requestOptions = {
      headers: { 
          'Content-Type': 'application/json',
          Authorization: `Bearer ${currentUser.authentication.token}` 
      }
  };

  useEffect(()=>{
    fetch('api/MarketStatistics/DailyTotals?fromDay=' + selectedDateRange!.fromDay + '&toDay='+selectedDateRange!.toDay, requestOptions)
    .then(res => res.json())
    .then((data) => {
        console.log("::DataHeader DailyTotals", {'fromDay': selectedDateRange!.fromDay, 'toDay': selectedDateRange!.toDay, 'data': data});
        setChartData(data);
    })
    .catch(console.log);    
  }, [selectedDateRange]);

  return <>
  <Layout style={{height: "15vh"}}  onMouseOver={()=>setShowSettings(true)}  onMouseOut={()=>setShowSettings(false)}>
  <Sider width={"15vw"} style={{backgroundColor:"#414141", padding: 20}}>
    <img width="170px" src={Logo}/>
  </Sider>

  <Content style={{overflow:"hidden", backgroundColor:"#414141"}}>
    <ResponsiveContainer>
      <LineChart data={chartData} margin={{ top: 10, right: 30, left: 10, bottom: 10 }}>
        <Tooltip content={<CustomTooltip />} />
        <Line type='monotone' dataKey='dailyTotal' stroke='#3eb55f' strokeWidth={2} />
      </LineChart>
    </ResponsiveContainer>

    <Button
      icon={<SettingOutlined style={{color:"white"}} />}
      size="middle"
      style={{position:"fixed", top:0, right: 0, zIndex: 1000, backgroundColor: "rgba(65,65,65,0)", borderColor:"transparent"}}
      className={settingsClasses} 
      onClick={()=>setShowDateSelectionModal(true)}/>

  </Content>
  
</Layout>

<Modal
    closeTimeoutMS={500}
    isOpen={showDateSelectionModal}
    contentLabel="onRequestClose Example"
    onRequestClose={()=>setShowDateSelectionModal(false)}
    style={{
      overlay: {
      },
      content: {
        top: (window.innerHeight - 140)/2,
        left: (window.innerWidth - 295)/2,
        height: 140,
        width: 295,
      }
    }}>
    <Row>
      <Col span={24} style={{backgroundColor:"#414141"}}>
        <h4 style={{color:"#ffffff"}}>Select date range</h4>
      </Col>
      <Col span={24}>
        <RangePicker value={[moment(modalDateRange.fromDay), moment(modalDateRange.toDay)]}  
        onChange={(values)=> {
          console.log("DataHeader::RangePicker", {values:values});
          setModalDateRange({fromDay: values![0]!.format("YYYY-MM-DD"), toDay: values![1]!.format("YYYY-MM-DD") })
        }}
        disabledDate={x=>!x.isBetween("2019-10-31", "2020-01-01")} />
      </Col>
      <Col span={24}>
        <Button
          icon={<CloseCircleOutlined style={{color:"Red"}} />}
          size="middle"
          style={{float:"left", marginLeft: "20px"}}
          onClick={()=>{resetDateRange(); setShowDateSelectionModal(false)}}>
          Cancel
        </Button>
        <Button
          icon={<CheckCircleOutlined style={{color:"Green"}} />}
          size="middle"
          style={{float:"right", marginRight:"20px"}}
          onClick={()=>{updateDateRange(); setShowDateSelectionModal(false)}}>
          OK
        </Button>
      </Col>
    </Row>
</Modal>

</>
}) as React.FunctionComponent;