import React, { useState, useContext, useEffect, memo } from 'react';
import { Tabs, Layout, Row, Col, Button, Radio, Spin } from 'antd';
import { IMerchantStoreDetails, SelectionContext, ActiveTab } from '../App';
import { FileAddOutlined, LoadingOutlined, PropertySafetyFilled } from '@ant-design/icons';
import { RadioChangeEvent } from 'antd/lib/radio';
import { isNullOrUndefined, isNull } from 'util';
import { ResponsiveContainer, BarChart, Bar, PieChart, Pie, Cell, Tooltip, ContentRenderer, PieLabelRenderProps } from 'recharts';
import { AuthenticationService } from '../services';

// todo: these are common, move into seperate module...
import { ChartBy, isMerchantStore } from '../pages/AnalyticsPage';
import { formatDate, PalleteColor, formatCurrency } from './Util';

const { TabPane } = Tabs;
const { Content, Sider } = Layout;

const BarChartTooltip = ( arg: any ) => {
    
    let tArg = arg as {payload: any[], active: boolean, chartByValue: ChartBy};
    console.log("BarTooltip", {"arg": arg, "chartByValue": arg.chartByValue});

    if (tArg.active && tArg.payload.length > 0) { // Is this correct now

    console.log("tArg.payload[0].payload.data", tArg.payload[0].payload.data);

        return (
            <div className="custom-tooltip">
            <p className="label">{formatDate(new Date(tArg.payload[0].payload.day))}</p>
            {tArg.payload.reverse().map( (x : any) => {

                if(x.value == 0)
                    return undefined; 

                var nationality = x.name.replace("data.","");
                var isActiveBar = false; //(arg.activeBarName == nationality)

                return <p key={nationality} className="desc" 
                        style={{fontWeight: isActiveBar?"bold":"unset"}}>{nationality} : {arg.chartByValue == ChartBy.TotalSpend ? formatCurrency(x.value) :  x.value}</p>;
            })}
            </div>
        );
    }
    
    return (<div />);
}

export default ( () =>{

    // TODO: figure this out...  :/
    const authenticationService = new AuthenticationService();

    // global selection context..
    const {selected, addToReport, setActiveTab, selectedDateRange, setMapState, nationalityPalette} = useContext(SelectionContext);

    // state
    const [graphData, setGraphData] = useState<{overallTotals: any, data: any[]} | undefined>(undefined);
    const [pieChartData, setPieChartData] = useState<{data: any[]} | undefined>(undefined);
    const [chartByValue, setChartByValue] = useState(ChartBy.TotalSpend);
    // TODO: should this be undefined...
    const [merchantDetails, setMerchantDetails] = useState<IMerchantStoreDetails | null>(null); 
    const [activeBar, setActiveBar] = useState<string>();

    const RADIAN = Math.PI / 180;  
    const renderLabel: ContentRenderer<PieLabelRenderProps> = ({cx, cy, midAngle, innerRadius, outerRadius, percent, index} ) => {
        const radius:number = (innerRadius as number) + ((outerRadius  as number) - (innerRadius  as number)) * 1.5;
        const x:number = (cx as number) + radius * Math.cos(-midAngle! * RADIAN);
        const y:number = (cy as number) + radius * Math.sin(-midAngle! * RADIAN);
      
        const item = pieChartData![index!];
      
        return (
          <text x={x} y={y} fill="black" textAnchor={x > (cx as number) ? 'start' : 'end'} dominantBaseline="central" key={`label-${item.Id}-${item.Id}`}>
            {item.name}
          </text>
        )
    };

    // TODO: Refactor request / fetch 
    const currentUser = authenticationService.currentUserValue;

    const requestOptions = {
        headers: { 
            'Content-Type': 'application/json',
            Authorization: `Bearer ${currentUser.authentication.token}` 
        }
    };

    useEffect(() => {
        if(isMerchantStore(selected)){
            console.log("useEffect::selected change", {selected: selected});
        }
        else{
            console.log("useEffect::selected mount", {selected: selected});
        }

        if(isMerchantStore(selected)){
            //load 
            fetch('api/MarketStatistics/MerchantStoreDetails?merchantStoreID='+selected!.id+'&fromDay='+selectedDateRange!.fromDay+'&toDay='+selectedDateRange!.toDay, requestOptions)
            .then(res => res.json())
            .then((data) => {
            console.log("::MerchantDailyTotals", {'id': selected!.id, 'name': selected!.name, 'data': data});
            setMerchantDetails(
                data
            );
            })
            .catch(console.log);
        }

    }, [selected, selectedDateRange]);

    // load additional data for the merchant and the graphs
    useEffect(() => {
            if(chartByValue in [ChartBy.TotalSpend, ChartBy.TouristCount] ){
                console.log("MerchantStoreDerailPanel::useEffect::chartByValue change");

                fetch('api/MarketStatistics/MerchantStoreBarchartData?merchantStoreID='+selected!.id+'&fromDay='+selectedDateRange!.fromDay+'&toDay='+selectedDateRange!.toDay+'&chartBy='+chartByValue, requestOptions)
                .then(res => res.json())
                .then((data) => {
                    console.log("MerchantStoreDerailPanel::::barchart", {'id': selected!.id, 'name': selected!.name, 'data': data});
                    setGraphData(data);
                })
                .catch(console.log);

                fetch('api/MarketStatistics/MerchantStorePiechartData?merchantStoreID='+selected!.id+'&fromDay='+selectedDateRange!.fromDay+'&toDay='+selectedDateRange!.toDay+'&chartBy='+chartByValue, requestOptions)
                .then(res => res.json())
                .then((data) => {
                    console.log("MerchantStoreDerailPanel::piechartData", {'id': selected!.id, 'name': selected!.name, 'data': data});

                    setPieChartData(data);
                })
                .catch(console.log);
            }
            else{
                console.log("MerchantStoreDerailPanel::useEffect chartByValue mount");
            }
        },
        [selected, chartByValue]
    );

    // ...
    if ( !isMerchantStore(selected) ){
        return (
            <Content>
                <Row>
                    <Col span={24}><h6>No Selection</h6></Col>
                </Row>
                <Row>
                    <Col span={24}>Please select a merchant using the popup on the map for further details </Col>
                </Row>
            </Content>
        )
    }

    return (
        <>
        <Content>
            {/* Merchant only for now */}

            <Row>
                <Col span={24}><h6>{selected!.name} <Button style={{borderColor:"transparent"}} icon={<FileAddOutlined />} size="large" title="Add to report" 
                onClick={(e)=>{addToReport!(selected);setActiveTab!(ActiveTab.Report);setMapState!({ store: undefined});}}></Button></h6></Col>
            </Row>

            {
                merchantDetails && 
                <>
                    <Row>
                        <Col span={6}><b>Merchant</b></Col>
                        <Col span={18} style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}} title={merchantDetails.merchantName}> {merchantDetails.merchantName}</Col>
                        {/* <Col span={5}><Button type="link">&gt;&gt;</Button></Col> */}
                    </Row>
                    <Row>
                        <Col span={6}><b>Market</b></Col>
                        <Col span={18} style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}} title={merchantDetails.merchantMarket}>{merchantDetails.merchantMarket}</Col>
                        {/* <Col span={5}><Button type="link">&gt;&gt;</Button></Col> */}
                    </Row>
                    <Row>
                        <Col span={6}><b>Tourists</b></Col>
                        <Col span={18}>{merchantDetails.touristCount}</Col>
                        {/* <Col span={5}><Button color="pink" type="link">&gt;&gt;</Button></Col> */}
                    </Row>
                    <Row>
                        <Col span={6}><b>Purchases</b></Col>
                        <Col span={18}>{merchantDetails.totalPurchases}</Col>
                        {/* <Col span={5}><Button type="link">&gt;&gt;</Button></Col> */}
                    </Row>
                    <Row>
                        <Col span={6}><b>Chart by</b></Col>
                        <Col span={18}>
                            <Radio.Group onChange={(e:RadioChangeEvent)=>{setChartByValue(e.target.value as ChartBy)}} value={chartByValue}>
                                <Radio value={ChartBy.TotalSpend}>
                                Total Spend 
                                </Radio>
                                <Radio value={ChartBy.TouristCount}>
                                Tourists
                                </Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                </>
            }
            {
                isNullOrUndefined(merchantDetails) &&
                <Row>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                </Row>
            }
        </Content>

        <Content style={{height:"20vh"}}>
        {
            graphData && (()=>{console.log("MerchantStoreDerailPanel::<BarChart>", {graphData:graphData});return true;})() &&
            <ResponsiveContainer>
                {/* TODO: Memoise this.. activeBar change should not reload control, then turn back on active bar */}
                <BarChart data={graphData!.data} >
                    <Tooltip content={<BarChartTooltip {...{chartByValue:chartByValue}}/>} />
                    { graphData.overallTotals.reverse().map( (x:any, index:any) =>
                        {
                            return <Bar dataKey={"data."+x.nationality} key={x.nationality} stackId="x" stroke={nationalityPalette![x.nationality]} fill={nationalityPalette![x.nationality]} strokeWidth={2} 
//                                        onMouseEnter={()=>{setActiveBar(x.nationality)}}
                                        />
                        }
                    )}
                </BarChart>
            </ResponsiveContainer>
        }
        {
            isNull(graphData) &&
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        }
        </Content>
        {/* <Row>
            <Col span={24}><Button type="link" style={{float:"right"}}>Details &gt;&gt;</Button></Col>
        </Row> */}

        <Content style={{height:"30vh"}}>
        {
            pieChartData && (()=>{console.log({"pieChartData":pieChartData}); return true;
        })() &&
            <ResponsiveContainer>
                <PieChart margin={{ top: 10, right: 30, left: 10, bottom: 10 }} >
                    <Pie dataKey="value" fill="#3eb55f" data={pieChartData.data} paddingAngle={0.01}> {/*labelLine={renderLabel}*/}
                    {
                        pieChartData.data.map((entry, index) => <Cell fill={nationalityPalette![entry.name]}/>)
                    }
                    </Pie>
                    <Tooltip />
                </PieChart>
            </ResponsiveContainer>
        }
        {
            isNull(pieChartData) &&
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }}  spin />} />
        }
        </Content>
        {/* <Row>
            <Col span={24}><Button type="link" style={{float:"right"}}>Details &gt;&gt;</Button></Col>
        </Row> */}

    </>);
}) as React.FunctionComponent;




            