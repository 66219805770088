import React, { Component } from 'react';
import { Layout, Row, Col } from 'antd';

import { LoginForm } from '../components/LoginForm';

import Logo from '../components/planet-logo-black.png';

const { Header, Footer, Sider, Content } = Layout;

export default class LoginPage extends Component {
    render () {
        console.log('LoginPage::render');
        
        return ( 
            <Content>
                <Row>
                    <Col span={8} offset={8}>
                        <Row>
                            <Col span={16} offset={8}><img src={Logo}/></Col>
                        </Row>
                        <LoginForm />
                    </Col>
                </Row>
            </Content>
        );
    }
}