import * as React from 'react';
import {Redirect, Route, RouteProps} from 'react-router';

import { AuthenticationService } from '../services';

export default class AuthenticatedRoute extends Route<RouteProps> {

    public render() {
        // TODO: fix
        let authenticationService = new AuthenticationService(); 
        const currentUser = authenticationService.currentUserValue;

        if (!currentUser) {
            console.log("Unauthenticated:: Redirecting to login");
            // not logged in so redirect to login page with the return url
            // "/login" must be defined as the authentication route in the application
            // todo: pass this in... better than hardcoding here... 
            const renderComponent = () => (<Redirect to={{pathname: '/login'}}/>);
            return <Route {...this.props} component={renderComponent} render={undefined}/>;
        }
        else {
            return <Route {...this.props}/>;
        }
    }
}