import React, { useState, useContext, useEffect } from 'react';
import { SelectionContext } from '../App';
import { Row, Col, Slider, Select, InputNumber, Layout, Checkbox, Radio } from 'antd';
import { AuthenticationService } from '../services';
import { SliderValue } from 'antd/lib/slider';

import { throttle } from 'lodash';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { RadioChangeEvent } from 'antd/lib/radio';
import { ChartBy } from '../pages/AnalyticsPage';

const { Option } = Select;
const { Content, Sider } = Layout;

class DecimalStep extends React.Component<{min:number, max:number, step:number, inputValue?:number, setValue(value: number): void}, {}> {

    // defaultProps = {
    //     min: 0, 
    //     max: 100, 
    //     step: 1
    // }

    onChange = (value: number | undefined | SliderValue) => {
      let val = value as number;
      if (isNaN(val)) {
        return;
      }
      this.props.setValue(val);
    };
  
    render() {
      return (
        <Row>
          <Col span={12}>
            <Slider
              min={this.props.min}
              max={this.props.max}
              onChange={this.onChange}
              value={typeof this.props.inputValue === 'number' ? this.props.inputValue : 0}
              step={this.props.step}
            />
          </Col>
          <Col span={4}>
            <InputNumber
              min={this.props.min}
              max={this.props.max}
              style={{ margin: '0 16px' }}
              step={this.props.step}
              value={this.props.inputValue}
              onChange={this.onChange}
            />
          </Col>
        </Row>
      );
    }
  }

interface RangeProps{
  min: number,
  max: number,
}

const Range = ((props:RangeProps)=>{
  const {min, max} = props;

  const {spendingLimits, setSpendingLimits} = useContext(SelectionContext);

  const [lower, setLower] = useState(spendingLimits!.lower == -1 ? min :  spendingLimits!.lower); 
  const [upper, setUpper] = useState(spendingLimits!.upper == -1 ? max :  spendingLimits!.upper);
  
  useEffect(
    ()=>{
      console.log("Range::UseEffect", {lower:lower, upper:upper});

      setSpendingLimits!( { lower: lower! == min ? -1 : lower!, upper: upper! == max ? -1 : upper!});
    },
    [lower, upper]
  )

  const sliderChangeR = (value:SliderValue)=>{
    if(value[0] != lower!){
      setLower(value[0]);
    }

    if(value[1] != upper){
      setUpper(value[1])
    }
  }

  const sliderChange = throttle(sliderChangeR, 50, {trailing: true});

  return <>
          <Row style={{padding: "0px 5px 0px 5px"}}>
            <Col span={24}>
              <Slider range value={[lower!, upper!]} min={min} max={max} onChange={sliderChange} />
            </Col>
          </Row>
          <Row style={{padding: "5px"}}>
            <Col span={2}>Min</Col>
            <Col span={10}>
              <InputNumber
                min={min}
                max={upper}
                style={{ margin: '0 16px' }}
                value={lower}
                onChange={(value:number | undefined)=>{ setLower(value!); }}
              />
            </Col>
            <Col span={2}>Max</Col>
            <Col span={10}>
              <InputNumber
                min={lower}
                max={max}
                style={{ margin: '0 16px' }}
                value={upper}
                onChange={(value:number | undefined)=>{ setUpper(value!); }}
              />
            </Col>
          </Row>
          { lower == min && upper == max &&
          <Row style={{padding: "5px"}}>
            <Col span={24}>
              <p style={{fontWeight:"bold"}}>No average spend filtering applied... </p>
            </Col>
          </Row>
          }
  </>
}) as React.FunctionComponent<RangeProps>;

export default (()=>{
    // TODO: figure this out...  :/
    const authenticationService = new AuthenticationService();

    // context
    const {selected, activeTab, selectedDateRange, mapState, setMapState, spendingLimits, setSpendingLimits, showAllNationalities, setShowAllNationalities, chartByValue, setChartByValue, nationalities } = useContext(SelectionContext);

    const currentUser = authenticationService.currentUserValue;
    const requestOptions = {
        headers: { 
            'Content-Type': 'application/json',
            Authorization: `Bearer ${currentUser.authentication.token}` 
        }
    };

    //TODO: this should be on the map rather than here...
    useEffect(
        () => {
            console.log("TouristDetailPanel::useEffect", {mapState:mapState!});
            
            let nationalitiesString = mapState!.heatmapNationalities!.join(",");

            if(showAllNationalities){
              nationalitiesString = "showAll";
            }

            // data/temp-heatmapdata.json
            fetch('api/MarketStatistics/HeatMapData?fromDay=' + selectedDateRange!.fromDay + '&toDay='+selectedDateRange!.toDay + '&nationalities=' + nationalitiesString + '&minimum=' + spendingLimits!.lower + '&maximum=' + spendingLimits!.upper + '&chartBy=' + ChartBy[chartByValue!] , requestOptions)
            .then(res => res.json())
            .then((data) => {
                console.log("TouristDetailPanel::useEffect HeatMapData", {'fromDay': selectedDateRange!.fromDay, 'toDay': selectedDateRange!.toDay, 'data': data});
                setMapState!({...mapState!, ...{heatmapData: data}});
            })
            .catch(console.log);    
        },
        [selectedDateRange, mapState!.heatmapNationalities, spendingLimits, showAllNationalities, chartByValue]
    )

    return <>
      <Content>
          <Row style={{padding: "0px 5px 10px 5px"}}>
              <Col span="24" style={{borderBottom:"#dadada solid 1px"}}><h5>Tourist Heatmap</h5></Col>
          </Row>
          <Row style={{padding: "5px"}}>
              <Col span={6}><b>Chart by</b></Col>
              <Col span={18}>
                  <Radio.Group onChange={(e:RadioChangeEvent)=>{setChartByValue!(e.target.value as ChartBy)}} value={chartByValue}>
                      <Radio value={ChartBy.TotalSpend}>
                      Total Spend 
                      </Radio>
                      <Radio value={ChartBy.TouristCount}>
                      Tourists
                      </Radio>
                  </Radio.Group>
              </Col>
          </Row>
          <Row style={{padding: "5px"}}>
              <Col span="12" style={{fontWeight:"bold"}}>Nationalities</Col>
              <Col span="12"><Checkbox checked={showAllNationalities} onChange={(e: CheckboxChangeEvent)=>{setShowAllNationalities!(e.target.checked)}}> Show All</Checkbox>
              </Col>
          </Row>
          <Row style={{padding: "5px"}}>
              <Col span="24">
                <Select
                  mode="multiple"
                  disabled={showAllNationalities}
                  style={{ width: '100%' }}
                  placeholder="Select nationalities"
                  defaultValue={mapState!.heatmapNationalities}
                  onChange={(selected: string[], option:any)=>{
                      setMapState!({...mapState!, ...{heatmapNationalities: selected}});
                  }}>
                  { nationalities!.map((nationality)=> 
                      <Option key={nationality} value={nationality}>{nationality}</Option>
                    )
                  }
                </Select>
              </Col>
          </Row>
          <Row style={{padding: "5px"}}>
              <Col span="24" style={{fontWeight:"bold"}}>Heatmap Intensity</Col>
          </Row>
          <DecimalStep min={0} max={30} step={0.1} inputValue={mapState!.heatmapIntensity} setValue={(value:number)=>{ setMapState!({...mapState!, ...{heatmapIntensity: value}});}} />

          {/* TODO: recode these, promote upper and lower to seperate variables, too much convoluted destructuring here to make it work  */}
          
          { chartByValue == ChartBy.TotalSpend &&
          <>
            <Row style={{padding: "5px"}}>
                <Col span="24" style={{borderBottom:"#dadada solid 1px", marginTop:"1em"}}><h5>Average Spend</h5></Col>
            </Row>
            <Range min={0} max={10000}/>
          </>
          }

      </Content>
    </>;
}) as React.FunctionComponent;