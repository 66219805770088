import 'bootstrap/dist/css/bootstrap.css';
import 'antd/dist/antd.css';

//import darkTheme from '@ant-design/dark-theme';

import './custom.css'

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import registerServiceWorker from './registerServiceWorker';

const rootElement = document.getElementById('root');

ReactDOM.render(
    <App />
  ,
  rootElement);

registerServiceWorker();
