import React, { useContext, useState } from 'react';
import { Table, Button, Row, Col, Modal, Layout } from 'antd';
import { MinusOutlined } from '@ant-design/icons';
import { SelectionContext } from '../App';
import { Link } from 'react-router-dom';

export default (() => {

    const {reportData, removeFromReport} = useContext(SelectionContext);

    return (
        <>
            <Table
                columns={[
                    {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name'
                    },
                    {
                        title: '',
                        key: 'action',
                        render: (text, record) => (
                            <Button
                                icon={<MinusOutlined />}
                                size="small"
                                title="Remove store from report"
                                style={{borderColor:"transparent", float:"right"}}
                                onClick={() => removeFromReport!(record)} /> 
                        )
                    }
                ]}
                dataSource={reportData}
                // rowKey="id"
                scroll={{ y: 'calc(85vh - 18em)' }}>
            </Table>

            <Row>
                <Col span={24}>
                    {/* <Button type="link" disabled style={{float:"left"}}>+Region</Button>
                    <Button type="link" disabled style={{float:"left"}}>+Market</Button>
                    <Link to={{pathname:"/report"}}><Button type="link" style={{float:"right"}}>Show Report &gt;&gt;</Button></Link> */}

                    <Link to={{pathname:"/report"}}><Button type="link">Show Report &gt;&gt;</Button></Link>
                </Col>
            </Row>
        </>
    );
}) as React.FunctionComponent;