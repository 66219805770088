import React, { Component, PureComponent, useState } from 'react';
import { Form, Modal, Input, Button, Checkbox } from 'antd';

import {AuthenticationService} from '../services/authentication.service';

import { Typography } from 'antd';


import 'antd/dist/antd.css';
import './login.css';

const { Text } = Typography;

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

export interface ILoginState{
    email: string;
    password: string;
    errorMessage: string;
}

export class LoginForm extends Component<{}, ILoginState> {

    public state: ILoginState = {
      email:"",
      password: "",
      errorMessage: ""
    }

    private authenticationService: AuthenticationService;
    private errorMessage:string = "";
    constructor(props: Readonly<{}>){
      super(props);

      this.authenticationService = new AuthenticationService();
    }

    onFinish = ( values: any) => { // Store
      console.log('Success:', values);

      this.authenticationService.login(values.email, values.password)
        .catch((errorMessage) => {
          this.setState({errorMessage: errorMessage});
        })
        .then( (userdata) => {
          // if userdata is sent from service we are logged in
          if(userdata){
            console.log("LoginForm::onFinish login callback", {"userdata":userdata});
            window.location.assign("/");
          }
        })
    }

    private onFinishFailed( errorInfo : any ) {
        console.log('Failed:', errorInfo);
    }

    render () {
      const {email, password, errorMessage} = this.state;
      console.log('LoginForm::render');

      return (
      <Form
            {...layout}
            name="basic"
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  type:"email",
                  message: 'Please input your email!',
                },
              ]}
              className="loginLabel"
            >
              <Input onChange={(e)=>{this.setState({errorMessage: ""});}}/>
            </Form.Item>
      
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
              className="loginLabel"
            >
              <Input.Password onChange={(e)=>{this.setState({errorMessage: ""});}}/>
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Text type="danger">{errorMessage}</Text>
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>);
    }
}