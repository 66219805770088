import React, { Component, useState, useContext, useEffect } from 'react';

import { Layout, Button, Menu, Checkbox, Popover, Empty, Tabs, Col, Row, Table, Radio, Spin } from 'antd';

import Map, { MerchantStoreInfo } from '../components/Map';
import DataHeader from '../components/DataHeader';
import {ShopOutlined, StarOutlined, UserOutlined, PlusOutlined, LeftSquareOutlined, RightSquareOutlined, AppleOutlined, AndroidOutlined, DoubleLeftOutlined, DoubleRightOutlined, UpOutlined, LineChartOutlined, InfoOutlined, InfoCircleOutlined, UpCircleTwoTone, MinusOutlined, FileAddOutlined, GlobalOutlined, LoadingOutlined, BugOutlined, LogoutOutlined} from '@ant-design/icons';
import { ResponsiveContainer, BarChart, Bar, LineChart, Line, PieChart, Pie, Tooltip, Cell } from 'recharts';

import { FloatProperty } from 'csstype';
import { Label } from 'reactstrap';
import { RadioChangeEvent } from 'antd/lib/radio';
import { SelectionContext, IInformationEntity, ActiveTab, ActiveTabStrings, IMerchantStoreDetails, IReportItem } from '../App';
import { isUndefined, isNullOrUndefined, isNull } from 'util';
import { AuthenticationService } from '../services';
import { selection, select } from 'd3';
import { isEmpty, random } from 'lodash';
import { formatDate, PalleteColor } from '../components/Util';
import TabPanel, { Tab } from '../components/TabPanel';
import ReportBuilderPanel from '../components/ReportBuilderPanel';
import DebugPanel from '../components/DebugPanel';
import MerchantStoreDetailPanel from '../components/MerchantStoreDetailPanel';
import TouristDetailPanel from '../components/TouristDetailPanel';

const { TabPane } = Tabs;
const { Content, Sider } = Layout;

export enum ActiveMenuItem {
    Merchant,
    Tourist
}

export enum ChartBy {
    TotalSpend,
    TouristCount
}

export interface ITabPane{
    key: ActiveTab;
    title: string;
    tabIcon: JSX.Element;
    content: JSX.Element;
}

// TODO: refactor
export const isMerchantStore = (entity: IInformationEntity | undefined): entity is MerchantStoreInfo => {
    if(isUndefined(entity))
        return false;

    return 'name' in entity && 'id' in entity;
}

export default class AnalyticsPage extends Component {

    DetailPanel = () => {
        const [collapsed, setCollapsed] = useState(false);
        const [hidden, setHidden] = useState(false);

        const [chartByValue, setChartByValue] = useState(ChartBy.TotalSpend);

        const {activeMenuItem, selected, setSelected, reportData, removeFromReport, activeTab, setActiveTab, selectedDateRange, mapState} = useContext(SelectionContext);

        const authenticationService = new AuthenticationService();
        
        const BarTooltip = ( arg: any ) => {
            let tArg = arg as {payload: any[], active: boolean};
            console.log("BarTooltip", {"arg": arg});

            if (tArg.active && tArg.payload.length > 0) { // Is this correct now
                console.log("tArg.payload[0].payload.data", tArg.payload[0].payload.data);

                return (
                    <div className="custom-tooltip">
                    <p className="label">{formatDate(new Date(tArg.payload[0].payload.day))}</p>
                    {tArg.payload.map( (x : any) => {
                        console.log("BarTooltip:: payload",{"x":x});
                        if(x.value == 0)
                            return ""; //... 

                        if(chartByValue == ChartBy.TotalSpend)
                            return <text> {x.name.replace("data.","")} : {new Intl.NumberFormat('en-IE', { style: 'currency', currency: 'EUR' }).format(x.value)}<br/></text>

                        return <text> {x.name.replace("data.","")} : {x.value}<br/></text>
                    })}
                    </div>
                );
            }

            return <div />;
        }
        
        const ActiveSelectionTab = () =>{

            let [merchantDetails, setMerchantDetails] = useState<IMerchantStoreDetails | null>(null);
            let [graphData, setGraphData] = useState<{overallTotals: any, data: any[]} | undefined>(undefined);
            let [pieChartData, setPieChartData] = useState<{data: any[]} | undefined>(undefined);

            const {selected, addToReport, setActiveTab} = useContext(SelectionContext);

            // TODO: Refactor request / fetch 
            const currentUser = authenticationService.currentUserValue;
      
            const requestOptions = {
              headers: { 
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${currentUser.authentication.token}` 
              }
            };

            useEffect(() => {
                if(isMerchantStore(selected)){
                    console.log("useEffect::selected change", {selected: selected});
                }
                else{
                    console.log("useEffect::selected mount", {selected: selected});
                }

                if(isMerchantStore(selected)){
                    //load 
                    fetch('api/MarketStatistics/MerchantStoreDetails?merchantStoreID='+selected!.id+'&fromDay='+selectedDateRange!.fromDay+'&toDay='+selectedDateRange!.toDay, requestOptions)
                    .then(res => res.json())
                    .then((data) => {
                        console.log("::MerchantDailyTotals", {'id': selected!.id, 'name': selected!.name, 'data': data});
                        setMerchantDetails(data);
                    })
                    .catch(console.log);
                }
            }, [selected, selectedDateRange]);

            // load additional data for the merchant and the graphs
            useEffect(() => {
                    if(chartByValue in [ChartBy.TotalSpend, ChartBy.TouristCount] ){
                        console.log("useEffect::chartByValue change");

                        fetch('api/MarketStatistics/MerchantStoreBarchartData?merchantStoreID='+selected!.id+'&fromDay='+selectedDateRange!.fromDay+'&toDay='+selectedDateRange!.toDay+'&chartBy='+chartByValue, requestOptions)
                        .then(res => res.json())
                        .then((data) => {
                            console.log("::barchart MerchantStoreBarchartData", {'id': selected!.id, 'name': selected!.name, 'data': data});
                            setGraphData(data);
                        })
                        .catch(console.log);

                        fetch('api/MarketStatistics/MerchantStorePiechartData?merchantStoreID='+selected!.id+'&fromDay='+selectedDateRange!.fromDay+'&toDay='+selectedDateRange!.toDay+'&chartBy='+chartByValue, requestOptions)
                        .then(res => res.json())
                        .then((data) => {
                            console.log("::piechartData", {'id': selected!.id, 'name': selected!.name, 'data': data});
                            setPieChartData(data);
                        })
                        .catch(console.log);
                    }
                    else{
                        console.log("useEffect::chartByValue mount");
                    }
                },
                [chartByValue]
            );

            // 
            if ( !isMerchantStore(selected) ){
                return (
                    <Content>
                        <Row>
                            <Col span={24}><h6>No Selection</h6></Col>
                        </Row>
                        <Row>
                            <Col span={24}>Please select a merchant using the popup on the map for further details </Col>
                        </Row>
                    </Content>
                )
            }

            return (
                <>
                    <Content>

                        <Row>
                            <Col span={24}><h6>{selected!.name} <Button style={{borderColor:"transparent"}} icon={<FileAddOutlined />} size="large" title="Add to report" 
                            onClick={(e)=>{addToReport!(selected);setActiveTab!(ActiveTab.Report);this.setState({ store: undefined});}}></Button></h6></Col>
                        </Row>

                    { merchantDetails && 
                    <>
                        <Row>
                            <Col span={6}><b>Merchant</b></Col>
                            <Col span={13}>{merchantDetails.merchantName}</Col>
                            <Col span={5}><Button type="link">&gt;&gt;</Button></Col>
                        </Row>
                        <Row>
                            <Col span={6}><b>Market</b></Col>
                            <Col span={13}>{merchantDetails.merchantMarket}</Col>
                            <Col span={5}><Button type="link">&gt;&gt;</Button></Col>
                        </Row>
                        <Row>
                            <Col span={6}><b>Tourists</b></Col>
                            <Col span={13}>{merchantDetails.touristCount}</Col>
                            <Col span={5}><Button color="pink" type="link">&gt;&gt;</Button></Col>
                        </Row>
                        <Row>
                            <Col span={6}><b>Purchases</b></Col>
                            <Col span={13}>{merchantDetails.totalPurchases}</Col>
                            <Col span={5}><Button type="link">&gt;&gt;</Button></Col>
                        </Row>
                        <Row>
                            <Col span={6}><b>Chart by</b></Col>
                            <Col span={18}>
                                <Radio.Group onChange={(e:RadioChangeEvent)=>{setChartByValue(e.target.value as ChartBy)}} value={chartByValue}>
                                    <Radio value={ChartBy.TotalSpend}>
                                    Total Spend 
                                    </Radio>
                                    <Radio value={ChartBy.TouristCount}>
                                    Tourists
                                    </Radio>
                                </Radio.Group>
                            </Col>
                        </Row>
                    </>
                    }

                    { isNullOrUndefined(merchantDetails) &&
                        <Row>
                            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                        </Row>
                    }

                    </Content>

                    <Content style={{height:"15vh"}}>
                    { graphData &&
                        <ResponsiveContainer>
                            <BarChart data={graphData!.data} >
                                <Tooltip position={{x:0,y:0}} content={<BarTooltip />} />
                                {graphData.overallTotals.map( (x:any, index:any) =>
                                    {
                                        console.log("graphData.overallTotals", x);
                                        return <Bar dataKey={"data."+x.nationality} key={x.nationality} stackId="x" stroke={PalleteColor[index % PalleteColor.length]} fill={PalleteColor[index % PalleteColor.length]} strokeWidth={2} />
                                    }
                                )}
                                
                            </BarChart>
                        </ResponsiveContainer>
                    }
                    { isNull(graphData) &&
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                    }
                    </Content>
                    <Row>
                        <Col span={24}><Button type="link" style={{float:"right"}}>Details &gt;&gt;</Button></Col>
                    </Row>

                    <Content style={{height:"25vh"}}>
                    { pieChartData && (()=>{console.log({"pieChartData":pieChartData}); return true;})() &&
                        <ResponsiveContainer>
                            <PieChart margin={{ top: 10, right: 30, left: 10, bottom: 10 }} >
                                <Pie dataKey="value" fill="#3eb55f" data={pieChartData.data} paddingAngle={0.01}>
                                {
                                    pieChartData.data.map((entry, index) => <Cell fill={PalleteColor[index % PalleteColor.length]}/>)
                                }
                                </Pie>
                                <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>
                    }
                    { isNull(pieChartData) &&
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }}  spin />} />
                    }
                    </Content>
                    <Row>
                        <Col span={24}><Button type="link" style={{float:"right"}}>Details &gt;&gt;</Button></Col>
                    </Row>
                </>);
        }

        let arrow = <DoubleRightOutlined />
        let content = <Tabs animated={false} style={{marginLeft:10}} activeKey={ActiveTab[activeTab!]} 
                        onChange={(activeKey: string)=>{console.log("set active tab: ", activeKey, ActiveTab[activeKey]);setActiveTab!( ActiveTab[activeKey])}}>
            <TabPane
                tab={<span><InfoCircleOutlined />Info</span>}
                key="Info">
                <ActiveSelectionTab />
            </TabPane>
            <TabPane
                tab={<span><LineChartOutlined />Reporting</span>}
                key="Report">
            </TabPane>
        </Tabs>

        if(collapsed){
            arrow = <DoubleLeftOutlined />
            content = <> <Button
                            icon={<InfoCircleOutlined />}
                            size="middle"
                            onClick={() => { setActiveTab!(ActiveTab.Info);setCollapsed(false)} } />
                          <Button
                            icon={<LineChartOutlined />}
                            size="middle"
                            onClick={() => { setActiveTab!(ActiveTab.Report);setCollapsed(false)} } /> 
                      </>;
        }

        return (
            // each tab here is an enum in ActiveTab
            <TabPanel hidden={hidden} width="20vw" collapsedWidth="32px"
                        activeTab={ActiveTab[activeTab!]} setActiveTab={(tab:string)=>{console.log("Setting active tab", {"tab":tab});setActiveTab!(ActiveTab[tab])}}
                        collapsed={collapsed} setCollapsed={setCollapsed}>
                <Tab icon={<InfoCircleOutlined />}  tabKey="Info" title="Info" visible={selected!=undefined}>
                    <MerchantStoreDetailPanel />
                </Tab>
                <Tab icon={<UserOutlined/>} tabKey="Tourist" title="Tourist" visible={activeMenuItem == ActiveMenuItem.Tourist}>
                    <TouristDetailPanel />
                </Tab>
                {/* <Tab icon={<BugOutlined />}  tabKey="Debug" title="Debug">
                    <DebugPanel />
                </Tab> */}
                <Tab icon={<LineChartOutlined />} tabKey="Report" title="Report">
                    <ReportBuilderPanel/>
                </Tab>
            </TabPanel>
        );
    }

    // TODO: move to seperate file
    Sidebar = () => {
        const {activeMenuItem, setActiveMenuItem, setActiveTab, selected} = useContext(SelectionContext);
        const [collapsed, setCollapsed] = useState(true);
        const [visible, setVisible] = useState(false);
     
        let arrow = <DoubleRightOutlined />
        let floatPosition : FloatProperty = "left";
        if(!collapsed){
            arrow = <DoubleLeftOutlined />
            floatPosition = "right";
        }
        
        return (
            <Sider theme="light" trigger={null} collapsible collapsed={collapsed} collapsedWidth={55} >
                {/* <div className="logo" /> */}
                <Button
                    icon={arrow}
                    type="ghost"
                    shape="circle-outline"
                    size="middle"
                    style={{borderColor:"transparent", animationDuration: "0s !important", float: floatPosition}}
                    onClick={() => setCollapsed(!collapsed)} />

                <Menu mode="inline" inlineIndent={16} inlineCollapsed={true} selectedKeys={[ActiveMenuItem[activeMenuItem!]]} >
                    <Menu.Item key="Merchant" onClick={()=>{setActiveMenuItem!(ActiveMenuItem.Merchant); setActiveTab!(selected ? ActiveTab.Info : ActiveTab.Report )}}>
                        <ShopOutlined />
                        <span>Merchants</span>
                    </Menu.Item>
                    <Menu.Item key="Tourist" onClick={()=>{setActiveMenuItem!(ActiveMenuItem.Tourist); setActiveTab!(ActiveTab.Tourist)}}>
                        <UserOutlined />
                        <span>Tourists</span>
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item key="Logout" onClick={()=>window.location.assign("/logout")}>
                        <LogoutOutlined />
                        <span>Logout</span>
                    </Menu.Item>
                    {/* <Menu.Item key="3" disabled={true}>
                        <StarOutlined />
                        <span>Insight</span>
                    </Menu.Item> */}
                </Menu>
            </Sider>
        )
     }

     render () {
        return (
            <SelectionContext.Consumer>
            {value => (
                <>
                    <Content style={{height: "15vh", backgroundColor:"#414141"}}>
                        <DataHeader/>
                    </Content>
                    <Layout style={{height: "85vh", width:"100vw"}}>
                        <this.Sidebar/>
                        <Content >
                            <Map style={{minHeight: "85vh"}} {...value} />
                        </Content>
                        <this.DetailPanel />
                    </Layout>
                </>
            )}
            </SelectionContext.Consumer>
        );
    }
}