import React, { useState, useContext, useEffect } from 'react';
import {Layout, Spin, Row, Col, Button, Typography} from 'antd';

import { IInformationEntity, ActiveTab, SelectionContext } from '../App';

// TODO: refactor
import { isMerchantStore, ChartBy } from '../pages/AnalyticsPage';
import { MerchantStoreInfo } from './Map';
import { AuthenticationService } from '../services';
import { ResponsiveContainer, BarChart, Bar, PieChart, Pie, Cell, Tooltip, YAxis } from 'recharts';
import { PalleteColor, formatDate, formatCurrency } from './Util';
import { isNull } from 'util';
import { LoadingOutlined } from '@ant-design/icons';


const { Content, Sider } = Layout;

const BarChartTooltip = ( arg: any ) => {
//const BarChartTooltip = ( {payload, active, chartByValue} : {payload: any[], active: boolean, chartByValue: ChartBy} ) => {
 
    let tArg = arg as {payload: any[], active: boolean, chartByValue: ChartBy, activeBarName: string};

    if (tArg.active && tArg.payload.length > 0) { // Is this correct now
        return (
            <div className="custom-tooltip">
            <p className="label">{formatDate(new Date(tArg.payload[0].payload.day))}</p>
            {
                tArg.payload.reverse().map( (x : any) => {

                    if(x.value == 0)
                        return undefined; 

                    var nationality = x.name.replace("data.","");
                    var isActiveBar = (arg.activeBarName == nationality)

//                    console.log("BarTooltip:: ",{"x":x, activeBarName:arg.activeBarName, nationality: nationality, isActiveBar: isActiveBar });

                    return <p key={nationality} className="desc" 
                                style={{fontWeight: isActiveBar?"bold":"unset"}}>{nationality} : {arg.chartByValue == ChartBy.TotalSpend ? formatCurrency(x.value) :  x.value}</p>;
                })
            }
            </div>
        );
    }
    
    return (<div />);
}

export interface ReportPageMerchantDetailPanelProps {
    type: string, 
    id: number
    hoverNationality?: string,
    setHoverNationality: React.Dispatch<string | undefined>
}

export default React.memo( ((arg:ReportPageMerchantDetailPanelProps)=>{
    //todo: assume only merchant for now

    // TODO: figure this out...  :/
    const authenticationService = new AuthenticationService();
    
    // global selection context..
    const {selectedDateRange, chartByValue, nationalityPalette} = useContext(SelectionContext);
 
    // local state 
    const [merchantData, setMerchantData] = useState<{name: string, market: string, location: string, dailyMin: number, dailyMax: number, averageDailyTotal: number, averageTouristSpend: number, totalSpend: number, touristCount:number} | undefined>(undefined);
    const [graphData, setGraphData] = useState<{overallTotals: any, data: any[]} | undefined>(undefined);
    const [pieChartData, setPieChartData] = useState<{data: any[]} | undefined>(undefined);
    const [activeBarName, setActiveBarName] = useState<string>();

    // TODO: Refactor request / fetch 
    const currentUser = authenticationService.currentUserValue;

    const requestOptions = {
        headers: { 
            'Content-Type': 'application/json',
            Authorization: `Bearer ${currentUser.authentication.token}` 
        }
    };

    useEffect(()=>{
        fetch('api/MarketStatistics/ReportMerchantStatistics?merchantStoreID='+arg.id+'&fromDay=' + selectedDateRange!.fromDay + '&toDay='+selectedDateRange!.toDay, requestOptions)
        .then(res => res.json())
        .then((data) => {
            console.log("::ReportPageMerchantDetailPanel ReportMerchantStatistics", {'merchant store id': arg.id, 'data': data});
            setMerchantData(data);
        })
        .catch(console.log);
    }, [selectedDateRange]);
    
    useEffect(() => {
        console.log("render::useEffect");
        if(chartByValue! in [ChartBy.TotalSpend, ChartBy.TouristCount] ){
            console.log("useEffect::chartByValue change");

            fetch('api/MarketStatistics/MerchantStoreBarchartData?merchantStoreID='+arg.id+'&fromDay=' + selectedDateRange!.fromDay + '&toDay='+selectedDateRange!.toDay + '&chartBy=' + ChartBy[chartByValue!] + "&topFive=true", requestOptions)
            .then(res => res.json())
            .then((data) => {
                console.log("::barchart MerchantStoreBarchartData", {'merchant store id': arg.id, 'data': data});
                setGraphData(data);
            })
            .catch(console.log);

            fetch('api/MarketStatistics/MerchantStorePiechartData?merchantStoreID='+arg.id+'&fromDay=' + selectedDateRange!.fromDay + '&toDay='+selectedDateRange!.toDay + '&chartBy=' + ChartBy[chartByValue!] + "&topFive=true", requestOptions)
            .then(res => res.json())
            .then((data) => {
                console.log("::piechartData", {'merchant store id': arg.id, 'data': data});

                setPieChartData(data);
            })
            .catch(console.log);
        }
        else{
            console.log("useEffect::chartByValue mount");
        }
    }, [selectedDateRange, chartByValue]);

    return (<Layout  style={{width: "80vw"}}>
                <Row style={{margin: "0em 1em 3em 1em"}}>
                    <Col span="6">
                    {merchantData && 
                        <>
                            <Typography.Text style={{width: "20vw"}} title={merchantData.name} ellipsis={true}><h5>{merchantData.name}</h5></Typography.Text>
                            <Row>
                                <Col style={{fontWeight: "bold"}} span="12">Location</Col>
                                <Col span="12" style={{textAlign: "right"}}>{merchantData.location}</Col>
                            </Row>
                            <Row>
                                <Col style={{fontWeight: "bold"}} span="12">Market</Col>
                                <Col span="12" style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", textAlign: "right"}} title={merchantData.market}>{merchantData.market}</Col>
                            </Row>
                            <Row>
                                <Col style={{fontWeight: "bold"}} span="12">Total</Col>
                                <Col span="12" style={{textAlign: "right"}}>{formatCurrency(merchantData.totalSpend)}</Col>
                            </Row>
                            <Row>
                                <Col style={{fontWeight: "bold"}} span="12">Avg Daily Total</Col>
                                <Col span="12" style={{textAlign: "right"}}>{formatCurrency(merchantData.averageDailyTotal)}</Col>
                            </Row>
                            <Row>
                                <Col style={{fontWeight: "bold"}} span="12">Tourists</Col>
                                <Col span="12" style={{textAlign: "right"}}>{merchantData.touristCount}</Col>
                            </Row>
                            <Row>
                                <Col style={{fontWeight: "bold"}} span="12">Avg Tourist Spend</Col>
                                <Col span="12" style={{textAlign: "right"}}>{formatCurrency(merchantData.averageTouristSpend)}</Col>
                            </Row>
                            <Row>
                                <Col style={{fontWeight: "bold"}} span="12">Min Day Total</Col>
                                <Col span="12" style={{textAlign: "right"}}>{formatCurrency(merchantData.dailyMin)} </Col>
                            </Row>
                            <Row>
                                <Col style={{fontWeight: "bold"}} span="12">Max Day Total</Col>
                                <Col span="12" style={{textAlign: "right"}}>{formatCurrency(merchantData.dailyMax)}</Col>
                            </Row>
                        </>
                    }

                    {!merchantData &&
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                    }
                    </Col>
                    <Col span="10">
                    {
                        graphData && nationalityPalette &&
                        <ResponsiveContainer>
                            <BarChart data={graphData!.data}>
                                <YAxis axisLine={false} />
                                <Tooltip content={<BarChartTooltip {...{chartByValue, activeBarName}} />} />
                                {graphData.overallTotals.map( (x:any, index:any) =>
                                    {
                                        // console.log("graphData.overallTotals", x);
                                        //  stroke={arg.hoverNationality==x.nationality?arg.nationalityPalette[x.nationality]:"#F0F2F5"}
                                        return <Bar isAnimationActive={false} className="nationalityHover" dataKey={"data."+x.nationality} key={x.nationality} stackId="x" fill={nationalityPalette[x.nationality]} strokeWidth={1} stroke="#F0F2F5" 
                                        onMouseEnter={(e) => {setActiveBarName(x.nationality)}}
                                        // onMouseEnter={(e)=>{arg.setHoverNationality(x.nationality)}} 
                                        // onMouseMove={(e)=>{arg.setHoverNationality(x.nationality)}} 
                                        // onMouseLeave={(e)=>{arg.setHoverNationality(undefined)}}
                                        />
                                    }
                                )}
                                
                            </BarChart>
                        </ResponsiveContainer>
                    }
                    {
                        isNull(graphData) &&
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                    }
                    </Col>
                    <Col span="8">
                    {
                        pieChartData && 
                        <ResponsiveContainer>
                            <PieChart margin={{ top: 10, right: 30, left: 10, bottom: 10 }} >
                                <Pie label={(x)=>{return x.name}} isAnimationActive={false} nameKey="name" dataKey="value" fill="#3eb55f" data={pieChartData.data} paddingAngle={0.01}>
                                {
                                    nationalityPalette &&
                                    pieChartData.data.map((entry, index) => {
//                                        console.log("ReportPageMerchantDetailsPanel:: map pie cell", {entry: entry});
                                        return <Cell key={entry.name} className="nationalityHover" fill={nationalityPalette[entry.name]} 
                                        // TODO: Replace with css!!!!
                                        // onMouseEnter={(e)=>{arg.setHoverNationality(entry.name)}} 
                                        // onMouseLeave={(e)=>{arg.setHoverNationality(undefined)}}
                                        />;
                                    })
                                }
                                </Pie>
                                <Tooltip formatter={(value) =>  chartByValue==ChartBy.TotalSpend ? formatCurrency(value as number) :value }/>
                            </PieChart>
                        </ResponsiveContainer>
                    }
                    {
                        isNull(pieChartData) &&
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }}  spin />} />
                    }
                    </Col>
                </Row>
            </Layout>
        );
}) as React.FunctionComponent<ReportPageMerchantDetailPanelProps>);